import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                <Title>Simulacres de Psicotècnics Aptitudinals en directe (30 desembre)</Title>
                <Text>
                    Després de l'èxit de l'any passat, on més de 280 alumnes van participar en el
                    simulacre de psicotècnics en directe a través de ZOOM, arriba enguany una nova
                    edició encara més renovada.
                    <br />
                    <br />
                    Amb exercicis molt semblants als de l'any passat i nous exercicis que podrien
                    aparèixer a l'examen.
                    <br />
                    <br />
                    Entra ara al campus i posa't a prova.
                    <br />
                    <br />
                    Dins del campus, trobaràs el simulacre amb 80 preguntes, el vídeo explicatiu i
                    les respostes.
                    <br />
                    <br />
                    Si vols desafiar-te de cara al 13 de gener...
                    <br />
                    <br />
                    El campus estarà disponible fins al dia de l'examen.
                </Text>

                {/*<div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/GEBTZGjlQ7I"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>*/}
            </div>
        </ProductTemplate>
    );
};
export default Product;
